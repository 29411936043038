@import "../../bower/normalize-scss/_normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html, body {
	height: 100%;
}
body {
	margin: 0;
	padding: 0;

	&.loaded {
		#preloader {
			.image-holder:after {
				width: 188px;
			}
		}
	}
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
ul {
	padding-left: 0;
}
li {
	list-style: none;
}

$Scarlet  		:#DC1A00;
$BrownPod 		:#3C2922;
$DonkeyBrown	:#7D6C61;
$Ironstone		:#865545;
$AntiqueWhite 	:#F3E7D4;
$DirtyWhite		:#E9DDCA;
$White			:#FFFFFF;
$Black			:#000000;

$OpenSans-Regular: 'OpenSans-Regular';
$OpenSans-Bold: 'OpenSans-Bold';
$OpenSans-SemiBold: 'OpenSans-SemiBold';
$OpenSans-ExtraBold: 'OpenSans-ExtraBold';
$Roboto-Light: 'Roboto-Light';
$Roboto-Medium: 'Roboto-Medium';
$Roboto-Bold: 'Roboto-Bold';
$Didot: 'Didot';
$GillSans: 'GillSans';

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Regular.woff') format('woff'),
        url('../fonts/OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: url('../fonts/OpenSans-Extrabold.eot');
    src: url('../fonts/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Extrabold.woff2') format('woff2'),
        url('../fonts/OpenSans-Extrabold.woff') format('woff'),
        url('../fonts/OpenSans-Extrabold.ttf') format('truetype'),
        url('../fonts/OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Didot';
    src: url('../fonts/Didot-HTF-M96-Medium.eot');
    src: url('../fonts/Didot-HTF-M96-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Didot-HTF-M96-Medium.woff2') format('woff2'),
        url('../fonts/Didot-HTF-M96-Medium.woff') format('woff'),
        url('../fonts/Didot-HTF-M96-Medium.ttf') format('truetype'),
        url('../fonts/Didot-HTF-M96-Medium.svg#Didot-HTF-M96-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GillSans';
    src: url('../fonts/GillSans.eot');
    src: url('../fonts/GillSans.eot?#iefix') format('embedded-opentype'),
		url('../fonts/GillSans.otf') format('opentype'),
        url('../fonts/GillSans.woff') format('woff'),
        url('../fonts/GillSans.ttf') format('truetype'),
        url('../fonts/GillSans.svg#GillSans') format('svg');
    font-weight: normal;
    font-style: normal;
}


body {
	// background: $AntiqueWhite;
}

.ironstone {
	color: $Ironstone;
}

#preloader {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	// background: #F3E7D4;
	z-index: 999999;

	.image-holder {
		width: 188px;
		height: 188px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		overflow: hidden;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 5;
		}

		&:after {
			content: "";
			z-index: 1;
			background: url('../img/app/preloader-image-filled.png');
			width: 10px;
			height: 188px;
			position: absolute;
			top: 0;
			left: 0;
			transition: 1.5s;
			background-attachment: fixed;
    		background-position: center;
		}
	}
}

#distinto {
	display: none;
	color: $Ironstone;
	font-size: 16.67px;
	font-family: $Roboto-Light;
	.div-content {
		min-height: calc(100vh - 95px - 35px - 30px - 50px);
	}

	section.section {
		h2.section-header {
			font-size: 40px;
			line-height: 30px;
			color: $BrownPod;
			font-family: $OpenSans-ExtraBold;
			text-transform: uppercase;
			letter-spacing: 4px;
			margin: 0;
			padding: 30px 0 50px;
			text-align: center;

			@media screen and (max-width: 767px) {
				padding: 0 0 50px;
			}
			@media(max-width:500px){
				font-size: 24px;
				line-height: 40px;
			}
		}

		.section-content {
			font-size: 24px;
			line-height: 32px;

			@media screen and (max-width: 767px) {
				font-size: 20px;
				line-height: 30px;
			}

			p {
				margin: 0 0 10px;
			}

			strong {
				font-family: $Roboto-Medium;
				color: $BrownPod;
			}
		}
	}
	.clientele-section {
		padding: 50px 0 0;
		@media screen and (max-width: 767px) {
			padding: 50px 0 0;
		}
		.section-title {
			// background: $BrownPod;
			color: $BrownPod;
			font-family: $OpenSans-ExtraBold;
			padding: 10px 50px;
			// display: inline-block;
			font-size: 40px;
			display: block;
			text-align: center;
			letter-spacing: 4px;
			@media screen and (max-width: 767px) {
				padding: 10px 50px;
				line-height: 40px;
			}
			@media(max-width:535px){
				padding: 10px 90px;
			}
			@media(max-width:500px){
				font-size:35px;
				line-height: 40px;
			}
			@media(max-width:480px){
				max-width: 254px;
				padding:10px 0;
				margin:0 auto;
			}
		}
		.clienteles {
			margin: 50px auto;
			@media screen and (max-width: 767px) {
				margin: 30px auto 50px;
			}
			.clientele {
				outline: none;
				display: grid;
				grid-template-columns: 50% 50%;
				@media screen and (max-width: 767px) {
					grid-template-columns: 1fr;
					grid-template-rows: 200px auto;
				}
				.image {
					background-position: center center!important;
					background-size: cover!important;
				}
				.text {
					padding:  30px 20px;
					background: $DirtyWhite;
					margin: 50px 0;
					position: relative;
					@media screen and (max-width: 767px) {
						margin: 0;
					}
					.name {
						font-family: $OpenSans-SemiBold;
						text-transform: uppercase;
						letter-spacing: 2px;
						margin-bottom: 10px;
						font-size: 18.67px;
					}
					.description {
						color: $Black;
						font-size: 16px;
						font-family: $GillSans;
					}
					.left {
						position: absolute;
						top: 15px;
						right: 35px;
						cursor: pointer;
						opacity: 0.5;
						&:hover {
							opacity: 1;
						}
					}
					.right {
						position: absolute;
						top: 15px;
						right: 15px;
						cursor: pointer;
						opacity: 0.5;
						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
	#DistintoBreadCrumbs {
		font-family: $OpenSans-Bold;
		font-size: 14px;
		line-height: 14px;
		color: $BrownPod;
		text-transform: uppercase;
		margin-bottom: 40px;

		span.divider {
			padding: 0 5px;
		}
	}
	
	nav.navbar {
		border: none;
		margin: 35px 0 30px;

		.navbar-brand-holder {
			position: absolute;
			
			.navbar-brand {
				padding: 0;
				height: 40px;
	
				img {
					max-width: 196px;
				}
			}
		}

		
		button.navbar-toggle {
			border: none;
			background: transparent;
			margin: 0;
			padding: 9px 0;

			@media screen and (max-width: 767px) {
				display: block;
			}
			
			.icon-bar {
				// width: 18px;
				background: $BrownPod;
				-webkit-transition: all 0.2s;
				transition: all 0.2s;
			}

			.icon-bar:nth-of-type(1) {
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transform-origin: 10% 10%;
				-ms-transform-origin: 10% 10%;
				transform-origin: 10% 10%;  
			}

			.icon-bar:nth-of-type(2) {
				opacity: 0;
				filter: alpha(opacity=0);
			}

			.icon-bar:nth-of-type(3) {
				-webkit-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				transform: rotate(-45deg);
				-webkit-transform-origin: 10% 90%;
				-ms-transform-origin: 10% 90%;
				transform-origin: 10% 90%;
			}
		}

		button.navbar-toggle:hover {
			background: transparent;
		}

		button.navbar-toggle.collapsed {
			.icon-bar:nth-of-type(1) {
				-webkit-transform: rotate(0);
				-ms-transform: rotate(0);
				transform: rotate(0);
				
			}

			.icon-bar:nth-of-type(2) {
				opacity: 1;
				filter: alpha(opacity=100);
			}

			.icon-bar:nth-of-type(3) {
				-webkit-transform: rotate(0);
				-ms-transform: rotate(0);
				transform: rotate(0);
			}
		}

		
		.marketing-links {
			position: relative;

			margin-left: 196px;

			@media screen and (max-width: 1200px) {
				margin: 40px 0 0 0;
			}

			@media screen and (min-width: 767px) {
				display: block;
			}

			@media screen and (max-width: 767px) {
				width: 100%;
				background: $AntiqueWhite;
				border: 1px solid $BrownPod;
				// color: $dodgerBlue2;
				margin-top: 60px !important;
				position: absolute;
				z-index: 5;
				display: none;
			}
		}
		
		.marketing-links.collapsing,
    	.marketing-links.in {
			display: block;
		}

		.navbar-collapse {
			font-size: 14px;
			font-family: $OpenSans-Bold;
			text-transform: uppercase;

			ul.navbar-nav {
				margin: 11px 0 0 95px;

				li.nav-item {
					padding: 0 30px;
					position: relative;

					a {
						padding: 0;

						&:hover {
							+ .dropdown-menu {
								display: block;
							}
						}
					
						+ .dropdown-menu {
							top: 18px;
							padding: 10px;

							@media screen and (max-width: 767px) {
								position: relative;
								width: 100%;
								text-align: center;
							}
							
							&:hover {
								display: block;
							}

							a {
								color: $Ironstone;
								text-transform: capitalize;
								font-family: $OpenSans-SemiBold;

								&:not(:last-of-type) {
									margin-bottom: 8px;
									display: block;
									min-width: 210px;
								}
							}
						}
					}
				}

				li.nav-item.active {
					color: $Scarlet;
				}

				li.nav-item:first-of-type {
					padding: 0 30px 0 0;
				}

				li.nav-item:last-of-type {
					padding: 0 0 0 30px;
				}
			}
		}
	}

	footer#footer {
		background: $Black;
		color: $White;
		font-size: 14px;
		font-family: $OpenSans-Bold;
		min-height: 95px;
		padding: 35px 45px 0;


		.float-left {
			float: left;
			padding-top: 5px;
		}
		
		.float-right {
			float: right;
			font-size: 24px;

			i.fab {
				padding-left: 26px;
			}
		}
	}

	section#BannerSection, .banner-slider-container {
		outline: none;
		position: relative;
		.banners {
			width: 100%;
			height: 553px;
			background-color: $Ironstone;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-position: center center !important;

			overflow: hidden;
			position: relative;
			background-color: #000;

			.parallelogram {
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				width: 100%;
				height: 100%;
				border: none;
				-o-text-overflow: clip;
				text-overflow: clip;
				// background: linear-gradient(to right, rgba(0, 0, 0, 1.0) 0%, rgba(0, 0, 0, 0.9) 60%, rgba(0, 0, 0, 0.7) 95%);
				// background: linear-gradient(to right, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 60%, rgba(0, 0, 0, 0.6) 85%);
				background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0.5) 85%);
				// background: rgba(0, 0, 0, 0.6);
				-webkit-transform:    skewX(-20deg);
				transform:    skewX(-20deg);

				// margin-left: -45%;
				// margin-left: -50%;
				margin-left: -60%;
				top: 0;
				position: absolute;
				display: block;

				@media screen and (max-width: 991px) {
					margin-left: -50%;
				}
			}

			.content-holder {
				position: absolute;
				width: 100%;
				height: 553px;
				top: 0;
				left: 0;

				color: $White;
				font-family: $OpenSans-Bold;
				font-size: 14px;
				z-index: 3;

				.inner {
					width: 350px;
					// margin: 191px 0 0 100px;
					margin: 191px 0 0 60px;

					@media screen and (max-width: 991px) {
						margin: 191px 0 0 30px;
					}

					.title {
						font-size: 53.33px;
						letter-spacing: 4px;
						margin-left: -4px;
						margin-bottom: -5px;
					}

					.content {
						font-family: $GillSans;
						font-size: 16px;
						line-height: 30px;
						max-width: 310px;

						@media screen and (max-width: 767px) {
							max-width: 300px;
						}

						@media screen and (max-width: 991px) {
							max-width: 270px;
						}
					}

					.button {
						margin-top: 40px;
						
						a {
							background: $Scarlet;
							text-transform: uppercase;
							padding: 10px 53px;
    						border-radius: 30px;
						}
					}
				}
			}
			
			margin-bottom: 38px;

			img {
				max-width: 100%;
			}
		}
	}

	section#OurStorySection {
		margin-bottom: 60px;
		h2 {
			padding: 80px 0 64px;
		}

		&.ourstory {
			.section-content {
				color: $BrownPod;
				font-size: 20px;
				p {
					font-family: $GillSans;
					font-size: 16px;
					line-height: 25px;
					strong {
						font-size: 20px;
					}
				}
				ul {
					padding-left: 40px;
					text-align: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					> li {
						font-family: $GillSans;
						list-style-type: disc;
						font-size: 16px;
						line-height: 25px;
					}
				}
			}
		}
		&.service-call {
			font-family: $GillSans;
			font-size: 16px;
			line-height: 30px;

			.section-content {
				color: $BrownPod;
				font-size: 20px;
				p {
					font-family: $GillSans;
					font-size: 16px;
					line-height: 25px;
					strong {
						font-size: 20px;
					}
				}
				ul {
					padding-left: 40px;
					> li {
						font-family: $GillSans;
						list-style-type: disc;
						font-size: 16px;
						line-height: 25px;
					}
				}
			}
		}

		.inner {
			margin-top: 64px;

			.inner-item {
				border: 2px solid $DonkeyBrown;
				width: 252px;
				display: inline-block;
				margin: 0 15px;
				position: relative;
				vertical-align: top;

				.icon-holder {
					position: absolute;
					top: -64px;
					width: 100%;
					
					.icon {
						width: 122px;
						height: 122px;
						background: $BrownPod;
						border-radius: 100%;
						text-align: center;
						margin: 0 auto;
						overflow: hidden;
						@media screen and (max-width: 767px) { 
							width: 80px;
							height: 80px;
						}

						span {
							display: inline-block;
							height: 100%;
							vertical-align: middle;
						}
						
						img {
							vertical-align: middle;
							max-width: 70%;
						}
					}

				}

				.content-holder {
					margin-top: 58px;
					padding: 0 30px;

					.item-header {
						font-size: 18.67px;
						line-height: 24px;
						font-family: $OpenSans-Bold;
						text-transform: uppercase;
						padding: 20px 0 14px;
						letter-spacing: 3px;
					}
	
					.item-content {
						min-height: 200px;
						margin-bottom: 15px;
						@media screen and (max-width: 991px) { 
							min-height: 135px;
						}

						p {
							margin: 0;
							font-family: $GillSans;
							font-size: 16px;

							span,
							strong {
								color: $Scarlet;
							}

							strong {
								font-weight: normal;
							}
						}
						
					}
				}

				.content-link {
					a {
						font-family: $Roboto-Medium;
						background-color: $BrownPod;
						border-radius: 30px;
						color: #fff;
						display: inline-block;
						padding: 5px 25px;
						margin-bottom: 20px;
						font-size: 14px;
						img {
							max-width: 40px;
						}
					}
				}

			}

			.inner-item.no-border {
				border: none;
			}
		}
	}

	section.service-contact {
		padding: 0 80px;
		margin-bottom: 15px;
		.items {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			>a {
				font-family: $OpenSans-Bold;
				color: $BrownPod;
				display: inline-block;
				font-size: 18px;
				transition: color 250ms;
				&:hover {
					color: #DC1A00;
				}
				
			}
		}
		@media screen and (max-width: 767px){
			padding: 0;
		}
	}
	
	section.Testimonials-Section {
		font-family: $Roboto-Medium;
		font-size: 24px;
		color: $White;
		position: relative;

		.testimonials {
			text-align: center;
			padding-bottom: 60px;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-position: center center !important;
			position: relative;
		
			&::after {
				content: ' ';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(0, 0, 0, 0.6);
				z-index: 1;
			}

			height: 100%;
			
			.icon-holder {
				padding: 47px 0 27px;
				display: grid;
				justify-content: center;
				align-items: center;
				position: relative;
				z-index: 2;

				img {
					max-width: 122px;
					margin: 0 auto;
				}
				.icon-wrapper {
					border-radius: 50%;
					width: 122px;
					height: 112px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;
				}
			}
			.content-holder {
				padding: 0 50px;
				display: flex;
				// background-color: rgba(0, 0, 0, 0.3);
				// border-radius: 10px;
				// margin: 0 30px;
				position: relative;
				z-index: 3;

				.quotation-holder {
					font-family: $Didot;
					font-size: 160px;
					display: inline-block;
				}

				.quotation-holder.float-left {
					float: left;

					@media screen and (max-width: 767px) { 
						margin-top: -25px;
					}
				}

				.quotation-holder.float-right {
					float: right;

					@media screen and (max-width: 767px) { 
						margin-bottom: -55px;
					}
				}

				.content {
					width: 100%;
					display: inline-block;

					@media screen and (max-width: 767px) { 
						margin-top: -115px;
						margin-bottom: -30px;
					}

					.content-body {
						line-height: 35px;
						margin-bottom: 40px;
						font-family: $GillSans;
						font-size: 20px;

						@media screen and (max-width: 767px) { 
							line-height: 27px;
						}

						p {
							margin: 0;
						}
					}
				}
				
				.content-footer {
					text-transform: uppercase;
					text-align: right;
					font-size: 18.67px;
					font-family: $OpenSans-Bold;

					@media screen and (max-width: 767px) { 
						text-align: center;
						padding-bottom: 90px;
					}

					.company {
						margin-top: -5px;

						@media screen and (max-width: 767px) { 
							margin-top: 0;
						}
					}
				}
			}
		}
		
		.testimonials-slick {
			width: 100%;
			margin: 0 0 25px;

			.slick-list {
				// display: flex;
			}

			ul.slick-dots {
				position: relative;
				bottom: 0;
				margin: 8px 0 0;

				li {
					width: 18px;
					height: 18px;
					margin: 0 21px;
					
					button {
						width: 18px;
						height: 18px;
						padding: 0;
						border-radius: 100%;
						border: 2px solid $BrownPod;
						content: "";
						outline: none !important;
						font-size: 0;
						vertical-align: top;
		
						&:before {
							color: transparent;
						}
					}
				}

				li.slick-active {
					button {
						background: $BrownPod;
					}
				}
			}

		}
	}

	section#SocialMediaSection {
		font-size: 0;
		.title {
			width: 100%;
			text-align: center;
			>h2 {
				font-family: $OpenSans-ExtraBold;
				font-size: 40px;
				color: $BrownPod;
				padding: 20px 0 58px;
				letter-spacing: 4px;
				@media(max-width:500px){
					font-size:35px;
					line-height: 40px;
				}
			}
		}
		.col {
			display: inline-block;
			width: 20%;
			padding-top: 20%;
			position: relative;

			a {
				display: block;
				background-position:  center center; 
				background-size: cover;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
	
	section#ContactFormSection {
		position: relative;
		color: $BrownPod;
		
		.contact-header	{
			font-size: 40px;
			font-family: $OpenSans-ExtraBold;
			text-transform: uppercase;
			line-height: 1em;
			letter-spacing: 7px;
			
			@media(max-width:500px){
				font-size: 24px;
			}
		}

		.contact-description {
			margin-top: 25px;
			font-size: 20px;
			line-height: 26.67px;
		}

		.contact-form {
			margin-top: 45px;

			.form-container {
				max-width: 740px;
				margin: 0 auto;

				.form-group {
					label {
						font-size: 16.67px;

						.label-required {
							color: $Scarlet;
						}
					}
					input, select {
						height: 39px;
					}

					textarea {
						max-height: 121px;
						resize: none;
					}
					input, textarea, select {
						background-color: transparent;
						border-color: $BrownPod;
						border-radius: 0px;
						font-size: 16.67px;

						&:focus {
							box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(60, 41, 34, 0.6);
						}
					}

					ul.parsley-errors-list {
						color: $Scarlet;
						font-size: 14px;
					}
					&.repair {
						display: none;
					}
				}
				
				.btn-container {
					padding-bottom: 20px;

					.submit-btn {
						background-color: $Scarlet;
						color: white;
						font-size: 14px;
						font-family: $OpenSans-Bold;
						letter-spacing: 1px;
						padding: 11px 44px;
						border-radius: 25px;

						&:focus {
							outline: none;
						}	
			
						@media(max-width:500px){
							display: block;
							width: 100%;
						}
					}
				}

				#recaptchaContactForm {
					margin-bottom: 15px;

					ul.parsley-errors-list {
						color: $Scarlet;
						font-size: 14px;
					}
				}
			}
		}
	}

	section#MapSection {
		padding-top: 50px;
		.location-container {
			display: flex;
			flex-wrap: wrap;
			background-color: $Ironstone;
			
			.address-container {
				height: 578px;
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				background-position: center;
				background-size: cover;
				background-repeat: no-repeat;

				.address-holder {
					color: white;
					.icon-container {
						img {
							max-width: 100%;
						}
						margin-bottom: 35px;
					}

					.address-content {
						font-family: $Roboto-Medium;
						font-size: 20px;
						line-height: 26.67px;
						padding: 0px 30px;

						p {
							margin: 0;
						}
					}
				}
			}

			.map-container {
				width: 50%;
				height: 578px;

				#mapContent {
					height: 100%;
				}
			}
		}
	}

	#DistintoOurStory {
		#OurStorySection.section {
			padding: 0 80px;
			margin-bottom: 20px;

			@media screen and (max-width: 767px) {
				padding: 0;
			}

			h2.section-header {
				padding: 30px 0 50px;

			}

			.inner {
				margin-top: 6px;

				.inner-item {
					width: 213px;
					
					.icon-holder {
						position: relative;
						top: 0;
						width: 100%;
					}

					.content-holder {
						margin-top: 0px;
						padding: 0;
					}
				}
			}
			.logos-container-outer {

				.logos-header {
					font-family: $Roboto-Medium;
					font-weight: bold;
					font-size: 20px;
					color: $BrownPod;
					text-align: center;
					margin: 20px 0 ;
				}
				.logos-container {

					.logo {
						display: grid;
						justify-content: center;
						margin-bottom: 20px;					
						.name {
							font-size: 16px;
							font-family: $GillSans;
							text-align: center;
						}
						> div {
							display: flex;
							justify-content: center;
							flex-wrap: wrap;
							> .image {
								max-width: 300px;
								max-height: 200px;
								display: grid;
								align-content: center;
								justify-content: center;
								margin: 5px 15px 20px;
								@media screen and (max-width: 767px) {
									max-width: 150px;
									max-height: 120px;
								}
								> img {
									max-width: 100%;
									max-height: 100%;
								}
							}
						}
					}
				}
			}
		}
	}

	#DistintoProducts{
		#BrandContainer {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: stretch;

			padding: 0 35px;
			margin-bottom: 20px;

			&>a {
				display: grid;
				align-content: end;
				padding: 10px 0;
			}

			.brand {
				margin: 0 0 20px 0;
				width: 240px;

				.logo-holder {
					min-height: 140px;
					text-align: center;
					margin: 0 auto;
					display: grid;
					align-items: center;
					justify-content: center;

					span {
						display: inline-block;
						height: 100%;
						vertical-align: middle;
					}

					img {
						max-width: 100%;
						max-height: 140px;
						vertical-align: middle;
					}
				}

				.type-holder {
					font-family: $Roboto-Bold;
					font-size: 14px;
					text-transform: uppercase;
					color: $BrownPod;
					margin-top: 10px;
				}
			}
		}
		
		section#ProductsSectionList {
			.product-list-container {
				display: grid;
				grid-auto-flow: column;
				grid-template-columns: auto 1fr;
				grid-gap: 80px;
				@media screen and (max-width: 767px) {
					grid-gap: 20px;
					grid-auto-flow: row;
					grid-template-columns: auto;
				}
				.filter-container {
					display: inline-block;
					.list-container {
						font-family: $OpenSans-Regular;
						border-bottom: 1px solid $Ironstone;
						max-width: 190px;
						margin-bottom: 22px;
						@media screen and (max-width: 767px) {
							max-width: unset;
						}
						&:last-child {
							border: none;
							margin-bottom: 0;
						}
						.list-title {
							font-family: $OpenSans-Bold;
							margin-bottom: 22px;
							&:hover {
								color: $Scarlet;
							}
						}
						.collapse-title {
							cursor: pointer;
							display: grid;
							align-items: center;
							justify-content: space-between;
							grid-auto-flow: column;
							&.open {
								max-height: 100vh;

								.fa {
									-webkit-transition: all 0.5s;
									transition: all 0.5s;
									transform: rotate(90deg);
								}
								& + ul {
									max-height: 100vh;
								}
							}
						}
						ul {
							padding-left: 25px;
							margin-left: 7px;
							font-size: 15px;
							-webkit-transition: all 0.5s;
							transition: all 0.5s;
							max-height: 100vh;
							overflow: hidden;
							max-height: 0;
							@media screen and (max-width: 767px) {
								padding-left: 0;
							}
							li {
								margin-left: 15px;
								font-size: 16px;
								margin-bottom: 10px;
								font-family: $GillSans;
								line-height: 25px;
								@media screen and (max-width: 767px) {
									margin-left: 5px;
									margin-bottom: 15px;
								}
							}
						}
					}
				}
				.product-container-parent {
					display: inline-block;
					margin-bottom: 100px;
					font-family: $GillSans;
					font-size: 16px;
					ul {
						padding-left: 40px;
						>li {
							list-style: disc;
						}
					}
					ol {
						padding-left: 40px;
						>li {
							list-style: decimal;
						}
					}
					p {
						line-height: 25px;
					}

					.filter-input {
						display: grid;
						align-items: center;
						justify-content: space-between;
						grid-auto-flow: column;
						grid-gap: 20px;
						@media screen and (max-width: 767px) {
							grid-auto-flow: row;
							justify-content: center;
							.input-container {
								width: 290px;
								& > select {
									margin-right: 0;
									width: 290px;
								}
								& > input {
									width: 290px;
								}
							}
						}

						.input-container {
							font-family: $OpenSans-Regular;
							font-size: 12.5px;
							color: #282828;
							margin-bottom: 20px;
							position: relative;
							text-align: center;
							@media screen and (max-width: 767px) {
								margin-bottom: 0;
							}
							select {
								background-color: $DirtyWhite;
								height: 55px;
								padding: 0 20px;
								min-width: 190px;
								@media screen and (max-width: 767px) {
									margin-bottom: 5px;
								}
							}
							input {
								background-color: $AntiqueWhite;
								border: 1px solid $BrownPod;
								height: 55px;
								padding: 0 30px 0 20px;
								min-width: 190px;
								@media screen and (max-width: 767px) {
									background-color: transparent;
									& + i {
										position: absolute;
										right: 10px;
										top: 22px;
									}
								}
								
								& + .fa-search {
									margin-left: -30px;
									cursor: pointer;
								}
							}
							button {
								background-color: $DirtyWhite;
								height: 55px;
								border: 1px solid rgb(169, 169, 169);
								padding: 0 10px;
								@media screen and (max-width: 767px) {
									height: 35px;
									width: 100%;
								}
							}
						}
					}
					.product-container {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-template-rows: auto;
						grid-gap: 50px 20px;
						margin-bottom: 50px;

						&.display-3 {
							grid-template-columns: 1fr 1fr 1fr;
						}

						@media screen and (max-width: 1023px) {
							grid-template-columns: 1fr 1fr;
						}
						@media screen and (max-width: 767px) {
							grid-template-columns: 1fr;
							justify-items: center;
						}

						.product {
							border: 1px solid transparent;
							border-radius: 20px;
							min-height: 420px;
							padding: 30px;
							display: grid;
							grid-template-rows: minmax(100px, 280px) 12px auto auto;
							grid-gap: 10px;
							@media screen and (max-width: 1023px) { 
								padding: 10px;
							}
							@media screen and (max-width: 767px) { 
								width: 290px;
							}
						

							&:hover {
								border-color: $Black;
							}
							.img-container {
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;
								margin: 20px;
							}
							.brand {
								font-size: 10px;
								font-family: $OpenSans-ExtraBold;
							}
							.name {
								font-size: 15px;
								font-family: $OpenSans-SemiBold;
							}
							.description {
								font-size: 10px;
								font-family: $OpenSans-SemiBold;
								max-height: 5rem;
								overflow: hidden;
								p {
									margin: 0;
								}
							}
						}
					}
					.loadmore-container {
						text-align: center;						
						.btn-loadmore {
							font-family: $OpenSans-Regular;
							background-color: $DirtyWhite;
							padding: 10px 50px;
							border-radius: 20px;
						}
						.pagination-links {
							text-align: center;
							ul {
								li {
									& > span, & > a {
										color: $BrownPod;
									}
									&.active {
										& > span {
											color: $BrownPod;
											background-color: $AntiqueWhite;
											border-color: $DirtyWhite;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	#DistintoProduct {
		section#BrandSection {
			// #BrandDetailsContainer {
			// 	margin: 0 50px;
				
			// 	display: flex;
			// 	flex-direction: row;
			// 	flex-wrap: nowrap;
			// 	justify-content: space-around;
			// 	align-items: stretch;
				
			// 	align-items: top;
			// 	justify-content: center;
				
			// 	@media screen and (max-width: 1200px) { 
			// 		flex-wrap: wrap;
			// 	}

			// 	@media screen and (max-width: 767px) { 
			// 		margin: 0;
			// 	}

			// 	.logo-holder {
			// 		flex-basis: 450px;
			// 		flex-grow: 0;
			// 		flex-shrink: 0;

			// 		.inner {
			// 			margin: 0 auto;
			// 			text-align: center;
			// 			margin-bottom: 40px;

			// 			img {
			// 				max-width: 100%;
			// 			}

			// 			.brand-type {
			// 				font-family: $Roboto-Medium;
			// 				font-size: 20px;
			// 				color: $BrownPod;
			// 				text-transform: uppercase;
			// 				margin-top: 13px;
			// 			}
			// 		}
			// 	}

			// 	.details {
			// 		margin: 15px 0 35px 20px;
			// 		font-size: 20px;
			// 		line-height: 30px;
			// 		color: $BrownPod;

			// 		@media screen and (max-width: 1200px) { 
			// 			margin: 25px 0 35px;
			// 		}
			// 	}
			// }

			// #BrandProductsContainer {
			// 	display: flex;
			// 	flex-direction: row;
			// 	flex-wrap: wrap;
			// 	justify-content: space-around;
			// 	align-items: stretch;
			// 	margin: 0 50px;
			// 	width: 450px;

			// 	.product {
			// 		width: 225px;
			// 		margin-bottom: 20px;
			// 		border: 2px solid transparent;
			// 		&:hover {
			// 			border-color: $BrownPod;
			// 		}

			// 		.name {
			// 			font-family: $OpenSans-SemiBold;
			// 			font-size: 15px;
			// 			color: $BrownPod;
			// 			max-width: 227px;
			// 			margin: 22px auto;
						
			// 			p {
			// 				margin: 0;
			// 			}

			// 			strong {
			// 				font-family: $OpenSans-ExtraBold;
			// 			}
			// 		}

			// 		.image-holder {
			// 			height: 275px;
			// 			margin-bottom: 15px;

			// 			img {
			// 				max-height: -webkit-fill-available;
			// 			}
			// 		}
			// 	}
			// }
			#BrandDetailsContainer {
				margin: 0 50px;
				display: grid;
				grid-template-columns: 450px auto;
				grid-template-areas: 'logo details' 'products details';
				grid-gap: 60px;
				margin-bottom: 50px;

				@media screen and (max-width: 767px) {
					margin: 50px 0;
					grid-template-columns: auto;
					grid-template-rows: auto;
					grid-template-areas: 'logo' 'details' 'products';
				}

				.logo-holder {
					grid-area: logo;
					img {
						max-width: 100%;
					}			
				}

				.details {
					grid-area: details;
					.brand-logo {
						padding: 20px 0;
						border-bottom: 1px solid $DirtyWhite;
						img {
							max-width: 100%;
						}
					}
					.brand-type {
						color: $BrownPod;
						font-size: 22px;
						font-family: $Roboto-Medium;
						margin: 25px 0;
					}
					.brand-content {
						color: $BrownPod;
						font-size: 17px;
						font-family: $Roboto-Light;
						margin-bottom: 30px;
					}
					.brand-links {
						margin-bottom: 20px;
						a {
							padding: 15px 20px;
							min-width: 235px;
							display: inline-grid;
							grid-auto-flow: column;
							justify-content: space-between;
							border: 1px solid $Ironstone;
							font-size: 12px;
							font-family: $OpenSans-SemiBold;
							&:hover {
								background-color: $BrownPod;
							}
						}
					}
				}
				
				#BrandProductsContainer {
					grid-area: products;
					height: 360px;
					position: relative;
					@media screen and (max-width: 767px) {
						overflow: hidden;
						margin: 0px -12px;
					}

					.product-link {
						width: 225px;
						outline: none;					
					}
					
					.slick-arrow {
						position: absolute;
						bottom: -30px;
						cursor: pointer;
						opacity: 0.5;
						z-index: 1;
						&:hover {
							opacity: 1;
						}
						&.left {
							left: 45%;
							@media screen and (max-width: 767px) {
								left: -3px;
								bottom: 45%;
								padding: 10px 10px 10px 6px;
							}
						}
						&.right {
							right: 45%;
							@media screen and (max-width: 767px) {
								right: -3px;
								bottom: 45%;
								padding: 6px 10px 10px 10px;
							}
						}
					}

					.product {
						width: 225px;
						margin-bottom: 20px;
						border: 2px solid transparent;
						display: grid;
						justify-content: center;
						grid-template-rows: 60px auto;
						&:hover {
							border-color: $BrownPod;
						}
						@media screen and (max-width: 767px) {
							border: none;
							width: unset;
						}

						.name {
							font-family: $OpenSans-SemiBold;
							font-size: 15px;
							color: $BrownPod;
							max-width: 227px;
							margin: 10px auto;
							
							p {
								margin: 0;
							}

							strong {
								font-family: $OpenSans-ExtraBold;
							}
						}

						.image-holder {
							height: 275px;
							margin-bottom: 15px;
							display: grid;
							justify-content: center;
							align-content: center;

							img {
								max-height: -webkit-fill-available;
								max-width: 100%;
							}
						}
					}
				}
			}
		}

		section#ProductSection {
			margin: 0 75px 50px 65px;
			color: $BrownPod;

			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-around;
			align-items: stretch;
			
			align-items: top;
			justify-content: center;

			@media screen and (max-width: 1200px) { 
				flex-wrap: wrap;
			}

			@media screen and (max-width: 767px) { 
				margin: 0;
			}
			
			.image-holder {
				flex-basis: 500px;
				flex-grow: 0;
				flex-shrink: 0;
				@media screen and (max-width: 500px) { 
					flex-basis: 270px;
				}
				
				.image-holder-slick {
					position: relative;
					max-width: 500px;
					min-height: 500px;
					@media screen and (max-width: 500px) { 
						max-width: 270px;
						min-height: 270px;
					}


					img {
						max-width: 500px;
					}

					.slick-arrow {
						position: absolute;
						top: 50%;
						z-index: 1;
						width: 24px;
						height: 24px;
						border-radius: 20px;
						background-color: $DirtyWhite;
						padding: 4px 0;
						cursor: pointer;

						&.left {
							left: -25px;
						}

						&.right {
							right: -25px;
						}
					}

					.slick-dots {
						button {
							&::before {
								font-size: 20px;
							}
						}
					}
				}

				.inner {
					margin: 40px auto 0; 
					text-align: center;

					@media screen and (max-width: 1200px) { 
						margin: 0 auto 40px; 
					}

					img {
						// max-height: -webkit-fill-available;
					}
				}
			}

			.details-holder {
				margin-left: 100px;

				@media screen and (max-width: 1200px) { 
					margin: 0; 
				}

				.name-holder {
					font-family: $OpenSans-SemiBold;
					font-size: 40px;
					margin: 0 0 18px;

					@media screen and (max-width: 991px) { 
						text-align: center;
					}
	
					strong {
						font-family: $OpenSans-ExtraBold;
					}
				}

				.details {
					font-size: 16px;
					line-height: 25px;
					font-family: $GillSans;

					p {
						margin: 0 0 24px;
					}

					ul {
						margin: 0 0 0 20px;

						li {
							list-style-type: disc;
						}
					}

					ol {
						margin: 0 0 0 20px;

						li {
							list-style-type: decimal;
						}
					}

					.more {
						font-family: $OpenSans-Bold;
						font-size: 16.19px;
						color: $Scarlet;
						text-transform: uppercase;
						margin-bottom: 20px;
						float: right;

						@media screen and (max-width: 991px) { 
							float: left;
						}
					}
				}
			}
		}
		
		section#RecommendationSection {
			#RecommendationContainer {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-around;
				align-items: stretch;
		
				.recommendation {
					border: 2px solid $BrownPod;
					background: $BrownPod;
					color: $White;
					text-align: center;
					flex-grow: 1;
					flex-basis: 50%;

					@media screen and (max-width: 991px) {
						flex-basis: 100%;
					}

					.recommendation-header {
						font-family: $OpenSans-Bold;
						font-size: 19.44px;
						letter-spacing: 2px;
						text-transform: uppercase;
						padding: 40px 0 35px;

						@media screen and (max-width: 767px) {
							padding: 40px 15px 35px;
						}

					}

					.name {
						font-family: $OpenSans-SemiBold;
						font-size: 33px;
						line-height: 28px;
						padding: 0 0 40px;
						
						@media screen and (max-width: 767px) { 
							line-height: 40px;
							padding: 0 15px 40px;
						}
						
						strong {
							font-family: $OpenSans-ExtraBold;
						}
					}
		
					.image-holder {
						height: 340px;
						margin-bottom: 20px;
		
						img {
							max-height: -webkit-fill-available;
						}
					}
				}

				.recommendation.pair {
					background: none;
					color: $BrownPod;
				}
			}
		}
	}

	#DistintoNews {
		#NewsContainer {
			margin: 25px 50px 0;
			color: $BrownPod;

			@media screen and (max-width: 991px) { 
				margin: 25px 0 0;
			}
			
			.news {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-around;
				align-items: stretch;
				
				align-items: center;
				justify-content: center;
				
				margin: 0 0 50px;
				&:last-of-type {
					margin: 0;
				}

				.date-holder {
					border: 2px solid $BrownPod;
					text-align: center;

					flex-basis: 146px;
					flex-grow: 0;
					flex-shrink: 0;

					@media screen and (max-width: 991px) { 
						display: none;
					}
	
					.inner {
						padding: 33px;
						
						.month {
							font-family: $OpenSans-SemiBold;
							font-size: 25px;
							text-transform: uppercase;
						}
						
						.date {
							font-family: $OpenSans-Bold;
							font-size: 66.67px;
							line-height: 50px;
							padding: 10px 0;
						}
					}
	
				}
	
				.details-holder {
					margin: 0 100px;

					@media screen and (max-width: 991px) { 
						margin: 0;
					}

					.name {
						font-family: $Roboto-Medium;
						font-size: 20px;
						text-transform: uppercase;
						padding: 0 0 5px;
					}

					.date {
						font-family: $Roboto-Medium;
						font-size: 17px;
						padding: 5px 0;
						display: none;

						@media screen and (max-width: 991px) { 
							display: block;
						}
					}
					
					.blurb {
						font-size: 16px;
						line-height: 25px;
						margin: 0 0 8px;
						font-family: $GillSans;
					}
					
					a {
						font-family: $OpenSans-Bold;
						font-size: 14px;
						color: $Scarlet;
						text-transform: uppercase;
						letter-spacing: 2px;
					}
				}
			}
		}

		section#NewsViewSection {
			color: $BrownPod;

			.section-header {
				padding: 5px 0 50px;

				@media screen and (max-width: 767px) { 
					line-height: 40px;
				}

			}

			.article-header, .article-footer {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: space-around;
				align-items: stretch;
				
				align-items: top;
				justify-content: center;

				margin: 0 0 30px;

				&:last-of-type {
					margin: 0;
				}

				@media screen and (max-width: 991px) { 
					flex-wrap: wrap;
				}
				
				.image-holder {
					img {
						max-width: 100%;
					}
				}

				.share-widget {
					flex-basis: 185px;
					flex-grow: 0;
					flex-shrink: 0;

					font-family: $OpenSans-Bold;
					font-size: 14px;
					text-transform: uppercase;
					letter-spacing: 2px;

					&.footer {
						display: none;

						@media screen and (max-width: 991px) { 
							display: unset;
						}
					}

					@media screen and (max-width: 991px) { 
						flex-basis: 100%;
						display: none;
					}
					
					.inner {
						padding: 0 0 0 30px;

						@media screen and (max-width: 991px) { 
							padding: 10px 0 0;
							text-align: center;
							display: grid;
							grid-auto-flow: column;
							align-items: center;
							justify-content: center;
						}
					}

					.widget {
						width: 60px;
						height: 60px;
						border: 1px solid $BrownPod;
						text-align: center;
						margin: 10px 0 0;

						@media screen and (max-width: 991px) { 
							width: 30px;
							height: 30px;

							display: inline-block;
							margin: 0 10px;
							vertical-align: text-bottom;
						}
						
						i {
							vertical-align: text-bottom;
							font-size: 35px;
							line-height: 56px;
							margin-bottom: 3px;

							@media screen and (max-width: 991px) { 
								font-size: 18px;
    							line-height: 27px;
							}
						}
					}
				}
			}

			.article-content {
				font-size: 20px;
				margin: 0 15px 0 40px;

				@media screen and (max-width: 991px) { 
					margin: 0;
				}

				.event-container {
					display: flex;
					flex-direction: row;
					flex-wrap: nowrap;
					justify-content: space-around;
					align-items: stretch;
					
					align-items: center;
					justify-content: start;

					margin: 0 0 20px;

					@media screen and (max-width: 991px) { 
						flex-wrap: wrap;
					}

					.date-holder {
						border: 2px solid $BrownPod;
						text-align: center;

						flex-basis: 100px;
						flex-grow: 0;
						flex-shrink: 0;

						@media screen and (max-width: 991px) { 
							order: 1;
							display: none;
						}
		
						.inner {
							padding: 20px 0 30px;

							.month {
								font-family: $OpenSans-SemiBold;
								text-transform: uppercase;
							}
							
							.date {
								font-family: $OpenSans-Bold;
								font-size: 47.78px;
								line-height: 38px;
							}
						}
		
					}

					.event-details {
						margin: 0 0 0 45px;
						font-family: $GillSans;
						font-size: 16px;
						line-height: 25px;

						@media screen and (max-width: 991px) { 
							margin: 0;
						}

						p {
							line-height: 25px;
							margin: 0;
						}
					}
				}

				.content-container {
					margin: 0 0 25px;
					font-family: $GillSans;
					font-size: 16px;
					line-height: 25px;
					ul {
						padding-left: 40px;
						>li {
							list-style: disc;
						}
					}
					ol {
						padding-left: 40px;
						>li {
							list-style: decimal;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 991px) {
	#distinto {
		nav.navbar {

			.navbar-collapse {
				padding: 0px;

				ul.navbar-nav {
					margin-top: 15px;

					li.nav-item {
						padding: 0 15px;

						a {
							padding: 0;
						}
					}

					li.nav-item.active {
						color: $Scarlet;
					}

					li.nav-item:first-of-type {
						padding: 0 15px 0px 0px;
					}

					li.nav-item:last-of-type {
						padding: 0 15px;
					}
				}
			}
		}
		
		// section#BannerSection {
		// 	.banners {

		// 		.content-holder {
		// 			.inner {
		// 				margin: 191px 0 0 50px;
		// 			}
		// 		}
		// 	}
		// }

		section#OurStorySection {
			margin-bottom: 10px;
			font-family: 
			h2 {
				padding: 20px 0 40px;
			}

			.inner {
				display: flex;
				flex-wrap: wrap;
				flex-direction: column;

				.inner-item {
					margin: 0 auto;
					margin-bottom: 80px;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}

		
		section#MapSection {
			.location-container {
				flex-direction: column;

				.address-container {
					width: 100%;
				}
				.map-container {
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	#distinto {
		br.mobile {
			display: none;
		}
		
		nav.navbar {

			.navbar-collapse {

				ul.navbar-nav {
					margin: 0px;
					text-align: center;
					li {
						padding: 15px 20px !important;
					}
				}
			}
		}
		section#BannerSection, .banner-slider-container {
			.banners {
				max-height: calc(100vh - 135px);
				padding: 100% 0 0 0 !important;
		
				.parallelogram {
					-webkit-transform:    skewX(-52deg);
					transform:    skewX(-52deg);

					margin-left: -350px;
					display: block;
				}

				.content-holder {
					.inner {
						margin: 20px 0 0 30px;

						.title {
							font-size: 51.33px;
						}

						.content {
							font-size: 18px;
							line-height: 28px;
						}

						.button {
							margin-top: 20px;
						}
					}
				}
			}
		}
		
		section.Testimonials-Section {
			margin-bottom: 60px;
			.testimonials {
				padding-bottom: 0px;
				.content-holder {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					align-items: center;

					.content {
						max-width: 400px;
						margin-top: -100px;

						.content-body {
							margin-bottom: 20px;
						}
					}
				}
			}
		}
		
		section#SocialMediaSection {
			.title{
				h2{
					padding: 10px 20px;
					margin-top:0;
				}
			}
			.col {
				width: 50%;
			}
		}

		section#MapSection {
			.location-container {

				.address-container {
					height: 400px;
				}
				.map-container {
					height: 400px;
				}
			}
		}
		section#OurStorySection{
			h2{
				padding: 10px 20px;
			}

			.inner {
				.inner-item {
					// margin-bottom: 25px;
				}
			}
		}
	}
}


@media screen and (max-width: 550px) {
	#distinto {
			
		footer#footer {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			padding-bottom: 35px;

			.float-left {
				text-align: center;
			}
			
			.float-right {
				text-align: center;
				margin-top: 20px;
			}
		}

		section#BannerSection, .banner-slider-container {
			.banners {
				// height: auto;		
				.parallelogram {
					-webkit-transform:    skewX(-43deg);
					transform:    skewX(-43deg);

					margin-left: -260px;
				}

				.content-holder {
					.inner {
						max-width: 200px;

						.title {
							font-size: 24px;
						}

						.content {
							font-size: 14px;
							line-height: 24px;
						}

						.button {
							
							a {
								padding: 10px 20px;
							}
						}
					}
				}
			}
		}
			
		section.Testimonials-Section {
			font-size: 16px;
			
			.testimonials {
				.content-holder {
					padding: 0 20px;

					.quotation-holder {
						font-size: 100px;
					}

					.content {
						margin-top: -60px;
					}

					.content-footer {
						font-size: 14.67px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 425px) {
	#distinto {
		section#BannerSection, .banner-slider-container {
			.banners {
				// height: 400px;
				display: block!important;
				.parallelogram {
					-webkit-transform:    skewX(-36deg);
					transform:    skewX(-36deg);
					margin-left: -200px;
				}

			}
		}
	}
}

.mobile-content-holder {
	font-family: $OpenSans-Bold;
	color: #3C2922;
	.inner {

		.title {
			font-size: 53.33px;
			letter-spacing: 4px;
			margin-left: -4px;
			margin-bottom: -5px;
		}

		.content {
			font-family: $GillSans;
			font-size: 16px;
			line-height: 30px;
			max-width: 320px;
		}

		.button {
			margin: 40px 0 30px;
			a {
				background: $Scarlet;
				text-transform: uppercase;
				padding: 10px 53px;
				border-radius: 30px;
				color: #ffffff;
			}
		}
	}
}

#mobileBannerImage {
    padding-bottom: 0 !important;
    height: 400px !important;
	@media screen and (max-width: 425px) {
		display: block !important;
	}
}
#bgndVideo {
	display: block !important;

}

.vimeo-player {
	position: relative;
	overflow: hidden;

	&::after {
		content: ' ';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
	}

	.cover-image {
		position: absolute;
		top: 50%;
		right: 0; 
		left: 0;
		transform: translateY(-50%);
		z-index: 1;
		background-position: center!important;
		background-size: cover!important;
		background-repeat: no-repeat!important;
		transition:visibility 0.3s linear,opacity 0.3s linear;
	}
}

.product-view-filter {
	text-align: right;
	margin-bottom: 10px;
	@media screen and (max-width: 767px) {
		display: none;
	}

	.dropdown{
		position: relative;

		button {
			background-color: $DirtyWhite;
		}

		.dropdown-menu {
			right: 0;
			left: auto;
			padding: 10px;
			
			.header {
				color: $Black;
				font-family: $OpenSans-Bold;
				margin-bottom: 5px;
			}

			a {
				font-family: $OpenSans-Regular;
			}
		}

		&.open {
			.dropdown-menu {
				display: grid;
			}
		}
	}
}

.get-quotation-container {
	margin-bottom: 30px;
	
	.get-quotation-btn {
		background: $Scarlet;
		text-transform: uppercase;
		padding: 10px 53px;
		border-radius: 30px;
		color: $White;
		font-family: $OpenSans-Bold;
		display: inline-block;
		
		@media screen and (max-width: 425px) {
			display: block;
			text-align: center;
		}
	}
}

#BannerSectionSlider {
	.slick-arrow {
		position: absolute;
    top: calc(50% - 12px);
		z-index: 1;
		width: 24px;
		height: 24px;
		border-radius: 20px;
		background-color: $DirtyWhite;
		padding: 4px 0;
		text-align: center;
		cursor: pointer;

		&.left {
			left: -35px;
			@media screen and (max-width: 500px) { 
				left: -10px;
			}
		}

		&.right {
			right: -35px;
			@media screen and (max-width: 500px) { 
				right: -10px;
			}
		}
	}
}

#distinto section#ProductsSection {
	> h2.section-header {
    padding: 30px 0 20px;

		@media screen and (max-width: 767px) {
			padding: 10px 0 20px;
		}
	}
}